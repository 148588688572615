.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  border-radius: 4px;
  border: 3px solid var(--brand-color-main);
  color: var(--brand-color-main);
  background-color: var(--white);
  transition: background 200ms;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  font-size: 1em;

  @media screen and (min-width: 45em) {
    font-size: 1.2em;
  }

  &:hover {
    color: var(--white);
    background-color: var(--brand-color-main);
  }

  &IconRight {
    flex-direction: row-reverse;

    .icon {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }

  &Danger {
    border: 3px solid var(--error-color);
    color: var(--error-color);

    &:hover {
      color: var(--white);
      background-color: var(--error-color);
    }
  }

  &Disabled {
    border: 3px solid var(--gray);
    color: var(--gray);

    &:hover {
      border: 3px solid var(--gray);
      color: var(--gray);
      background-color: var(--white);
    }
  }

  @media screen and (min-width: 45em) {
    padding: 1rem 2rem;
  }
}

.icon {
  margin-right: 0.5rem;
}
